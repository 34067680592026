import * as React from "react"

const Ticker = ({ text, color = "white", type = "home" }) => {

    return (
        <div className={`text-${color} ${type === "sub" ? `md:text-8xl lg:text-9xl` : ``} overflow-hidden flex bg-transparent uppercase text-6xl`}>
            <div className="flex items-center flex-none min-w-full animate-scroll-left">
                <div className="flex items-center mx-3">
                  {type==="home" && <p className="font-extrabold">{text}</p>}
                  {type==="sub" && <h1 className="font-extrabold">{text}</h1>}
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-bold">•</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-light italic">{text}</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-extrabold">•</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-extrabold">{text}</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-extrabold">•</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-light italic">{text}</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-extrabold">•</p>
                </div>
                {type === "sub" && text == "LAB" &&
                    <>
                        <div className="flex items-center mx-3">
                            <p className="font-extrabold">{text}</p>
                        </div>
                        <div className="flex items-center mx-3">
                            <p className="font-extrabold">•</p>
                        </div>
                        <div className="flex items-center mx-3">
                            <p className="font-light italic">{text}</p>
                        </div>
                        <div className="flex items-center mx-3">
                            <p className="font-extrabold">•</p>
                        </div>
                    </>}
            </div>
            <div className="flex items-center flex-none min-w-full animate-scroll-left">
                <div className="flex items-center mx-3">
                    <p className="font-extrabold">{text}</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-extrabold">•</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-light italic">{text}</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-extrabold">•</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-extrabold">{text}</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-extrabold">•</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-light italic">{text}</p>
                </div>
                <div className="flex items-center mx-3">
                    <p className="font-extrabold">•</p>
                </div>
                {type === "sub" && text == "LAB" &&
                    <>
                        <div className="flex items-center mx-3">
                            <p className="font-extrabold">{text}</p>
                        </div>
                        <div className="flex items-center mx-3">
                            <p className="font-extrabold">•</p>
                        </div>
                        <div className="flex items-center mx-3">
                            <p className="font-light italic">{text}</p>
                        </div>
                        <div className="flex items-center mx-3">
                            <p className="font-extrabold">•</p>
                        </div>
                    </>}
            </div>
        </div>
    )
}


export default Ticker
