import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import Header from "../components/header"

//import the file below to the page to use translations on components
import "../../i18n.js";
import { useTranslation } from "react-i18next";

import Ticker from '../components/ticker'

const IndexPage = ({ data }) => {

  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState("studio")

  const sections = [
    { name: "studio", title: t("studioTitle"), description: t("studioDescription"), box: t("studioCTA") },
    { name: "lab", title: t("labTitle"), description: t("labDescription"), box: t("labCTA") },
    { name: "space", title: t("spaceTitle"), description: t("spaceDescription"), box: t("spaceCTA") }
  ];
  // translation: {t("hello")}
  return (
    <>
      <Seo title="Home" canonical="https://tio.ist/tr/" />
      <div className="">
        <Header siteTitle={`Tio`} />

        <div className="absolute top-0 w-full h-full md:h-screen flex flex-col md:flex-row">
          {sections.map((item, index) => (
            <Link
              key={index}
              className="w-full h-1/3 md:w-1/3 md:h-screen"
              onMouseEnter={() => setActiveSection(`${item.name}`)}
              to={`/${item.name}`}
            >
              {/* begin::desktop ui */}
              <div className={`${activeSection === item.name ? `bg-${item.name}-desktop` : ""} hidden lg:flex flex-col justify-center w-full h-full bg-no-repeat bg-blue bg-blend-multiply bg-cover`}>
                <div className={`${activeSection === item.name ? "" : "opacity-20 pb-7"} text-center`} >
                  <p className={`${activeSection === item.name ? "hidden" : "block"} text-white text-6xl font-bold md:mb-5 lg:mb-6`}>{item.title}</p>
                  <span className={`${activeSection === item.name ? "block" : "hidden"} md:mb-4 lg:mb-5`}>
                    <Ticker text={item.name} />
                  </span>
                  <p className="text-cream italic font-light md:text-base">{item.description}</p>
                  <div className="border border-cream text-cream md:text-sm lg:text-xl md:w-28 lg:w-40 md:py-4 lg:py-5 mx-auto md:my-9 lg:my-12">
                    {item.box}
                  </div>
                  <div className={activeSection === item.name ? "flex justify-center" : "hidden"}>
                    <svg className="fill-current text-cream" width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3431 0.929125L24.7071 7.29309C25.0976 7.68361 25.0976 8.31678 24.7071 8.7073L18.3431 15.0713C17.9526 15.4618 17.3195 15.4618 16.9289 15.0713C16.5384 14.6807 16.5384 14.0476 16.9289 13.657L21.5858 9.00019L-7.312e-07 9.00019L-5.56355e-07 7.00019L21.5858 7.00019L16.9289 2.34334C16.5384 1.95281 16.5384 1.31965 16.9289 0.929125C17.3195 0.538601 17.9526 0.538601 18.3431 0.929125Z" />
                    </svg>
                  </div>
                </div>
              </div>
              {/* end::desktop ui */}

              {/* begin::tablet ui */}
              <div className={`bg-${item.name}-desktop hidden md:flex lg:hidden flex-col justify-center w-full h-full bg-no-repeat bg-blue bg-blend-multiply bg-cover`}>
                <div className={`pb-7 text-center`} >
                  <span className={`md:mb-4 lg:mb-5`}>
                    <Ticker text={item.name} />
                  </span>
                  <p className="text-cream italic font-light md:text-base">{item.description}</p>
                  <div className="border border-cream text-cream md:text-sm lg:text-xl md:w-28 lg:w-40 md:py-4 lg:py-6 mx-auto md:my-9 lg:my-12">
                    {item.box}
                  </div>
                  <div className="flex justify-center">
                    <svg className="fill-current text-cream" width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.6593 6.68057C17.937 6.40286 17.937 5.95261 17.6593 5.6749L13.1339 1.14942C12.8562 0.871714 12.4059 0.871714 12.1282 1.14942C11.8505 1.42713 11.8505 1.87738 12.1282 2.15508L16.1508 6.17774L12.1282 10.2004C11.8505 10.4781 11.8505 10.9283 12.1282 11.2061C12.4059 11.4838 12.8562 11.4838 13.1339 11.2061L17.6593 6.68057ZM0.0898437 6.88885L17.1565 6.88885L17.1565 5.46662L0.0898438 5.46662L0.0898437 6.88885Z" />
                    </svg>
                  </div>
                </div>
              </div>
              {/* end::tablet ui */}

              {/* begin::mobile ui */}
              <div className={`bg-${item.name}-mobile flex flex-col justify-center md:hidden w-full h-full bg-blue bg-no-repeat bg-blend-multiply bg-cover`}>
                <div>
                  <Ticker text={item.name} />
                  <p className="text-cream italic font-light opacity-50 text-center">{item.description}</p>
                </div>
              </div>
              {/* end:mobile ui */}
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}
export default IndexPage

export const pageQuery = graphql`
  {
    allWpPost(
      filter: { language: { slug: { eq: "en" } } }
      sort: { order: DESC, fields: [date] }
    ) {
      edges {
        node {
          id
          uri
          excerpt
          content
          title
          author {
            node {
              id
              avatar {
                url
              }
              name
              uri
              description
            }
          }
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
